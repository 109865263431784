.subscribe-form-wrap {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 8000;
    background: #f6f9ff;
    align-items: center;
    justify-content: center;
    display: none;
}

.from-visible.subscribe-form-wrap {
    display: flex !important;
}

img.logo-subscribe-form {
    height: 55px;
    width: 55px;
}

.brPyfP {
    margin: 30px 0px 15px;
}

.kxAoka {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 6px;
}

.form-progress-bar {
    position: relative;
    height: 4px;
    background-color: rgb(241, 241, 241);
}

.form-progress-bar.c {
    background-color: var(--theme-color);
}

.brPyfP p {
    padding: 12.5px 0px;
    margin: 0px;
}

.dKuTtm {
    color: var(--colors-charcoal);
    font-size: 0.75rem;
    text-align: center;
}

button.btn-close.btn-close-form {
    display: flex;
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 100;
}

.subscribe-form-wrap main {
    scale: 0.95;
}

button.btn.btn-light.btbc.w-100 {
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
    margin-top: 10px;
}

.card.mb-3.crdfm {
    margin-top: 0;
    height: 0;
    width: 0;
    overflow: hidden;
}

.crdfm.active {
    height: auto !important;
    width: auto !important;
    margin-top: 15px !important;
}

.intl-tel-input {
    display: block;
}
.intl-tel-input .selected-flag {
    z-index: 4;
}
.intl-tel-input .country-list {
    z-index: 5;
}
.input-group .intl-tel-input .form-control {
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
}

.crds .card-body {
    max-width: 500px;
}

.subscribe-form:focus-within  {
    /* border-color: #86b7fe; */
    outline: none;
    /* box-shadow: 0 0 0 .2rem rgba(13,110,253,.25); */
}