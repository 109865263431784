/* Hero Section */
.hero {
    width: 100%;
    height: 100vh;
    background: url(../../assets/img/home/hero-bg.png) top center no-repeat;
    background-size: cover;
}

.hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    color: var(--theme-color-dark);
}

.hero h2 {
    color: #444444;
    margin: 15px 0 0 0;
    font-size: 26px;
}

.hero .btn-get-started {
    margin-top: 30px;
    line-height: 0;
    padding: 15px 40px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: var(--theme-color);
    box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
}

.hero .btn-get-started span {
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
}

.hero .btn-get-started i {
    margin-left: 5px;
    font-size: 18px;
    transition: 0.3s;
}

.hero .btn-get-started:hover i {
    transform: translateX(5px);
}

.hero .hero-img {
    text-align: right;
}

@media (min-width: 1024px) {
    .hero {
        background-attachment: fixed;
    }
}

@media (max-width: 991px) {
    .hero {
        height: auto;
        padding: 120px 0 60px 0;
    }

    .hero .hero-img {
        text-align: center;
        margin-top: 80px;
    }

    .hero .hero-img img {
        width: 80%;
    }
}

@media (max-width: 768px) {
    .hero {
        text-align: center;
    }

    .hero h1 {
        font-size: 32px;
    }

    .hero h2 {
        font-size: 24px;
    }

    .hero .hero-img img {
        width: 100%;
    }
}

/* About Section */
.about .content {
    background-color: #f6f9ff;
    padding: 40px;
}

.about h3 {
    font-size: 14px;
    font-weight: 700;
    color: var(--theme-color);
    text-transform: uppercase;
}

.about h2 {
    font-size: 24px;
    font-weight: 700;
    color: #012970;
}

.about p {
    margin: 15px 0 30px 0;
    line-height: 24px;
}

.about .btn-read-more {
    line-height: 0;
    padding: 15px 40px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: var(--theme-color);
    box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.3);
}

.about .btn-read-more span {
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
}

.about .btn-read-more i {
    margin-left: 5px;
    font-size: 18px;
    transition: 0.3s;
}

.about .btn-read-more:hover i {
    transform: translateX(5px);
}

/* Values */
.values .box {
    padding: 30px;
    box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);
    text-align: center;
    transition: 0.3s;
    height: 100%;
}

.values .box img {
    padding: 30px 50px;
    transition: 0.5s;
    transform: scale(1.1);
}

.values .box h3 {
    font-size: 24px;
    color: var(--theme-color-dark);
    font-weight: 700;
    margin-bottom: 18px;
}

.values .box:hover {
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}

.values .box:hover img {
    transform: scale(1);
}

/* Counts */
iframe.statisticsFrame {
    display: block;
    width: 100%;
    height: 290px;
    overflow: hidden;
    margin-top: -50px;
}

@media (max-width: 992px) {
    iframe.statisticsFrame {
        height: 415px;
    }
}


@media (max-width: 768px) {
    iframe.statisticsFrame {
        height: 725px;
    }
}

/* F.A.Q */
.faq .accordion-collapse {
    border: 0;
}

.faq .accordion-button {
    padding: 15px 15px 20px 0;
    font-weight: 600;
    border: 0;
    font-size: 18px;
    color: #444444;
    text-align: left;
}

.faq .accordion-button:focus {
    box-shadow: none;
}

.faq .accordion-button:not(.collapsed) {
    background: none;
    color: var(--theme-color);
    border-bottom: 0;
}

.faq .accordion-body {
    padding: 0 0 25px 0;
    border: 0;
}

/* Clients */
.clients .clients-slider .swiper-slide img {
    opacity: 0.9;
    transition: 0.3s;
    scale: 0.8;
}

.clients .clients-slider .swiper-slide img:hover {
    scale: 1;
}

.clients .clients-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.clients .clients-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid var(--theme-color);
}

.clients .clients-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--theme-color);
}

/* Testimonials */
.testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 30px;
    margin: 40px 30px;
    box-shadow: 0px 0 20px rgba(1, 41, 112, 0.1);
    background: #fff;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    text-align: center;
    transition: 0.3s;
}

.testimonials .testimonial-item .stars {
    margin-bottom: 15px;
}

.testimonials .testimonial-item .stars i {
    color: #ffc107;
    margin: 0 1px;
}

.testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50%;
    border: 4px solid #fff;
    margin: 0 auto;
}

.testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
}

.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
}

.testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid var(--theme-color);
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--theme-color);
}

.testimonials .swiper-slide {
    opacity: 0.3;
}

@media (max-width: 1199px) {
    .testimonials .swiper-slide-active {
        opacity: 1;
    }

    .testimonials .swiper-pagination {
        margin-top: 0;
    }
}

@media (min-width: 1200px) {
    .testimonials .swiper-slide-next {
        opacity: 1;
        transform: scale(1.12);
    }
}
