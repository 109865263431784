.CertificatePortalLoader {
    display: flex;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
}

.cpbh {
    color: var(--theme-color) !important;
    filter: brightness(0.8);
}

.certificateWindow {
    display: flex;
    height: 100%;
    width: 100%;
    min-height: 60vh;
    background: transparent;
    margin-top: 25px;
    border-radius: 10px;
    overflow: hidden;
    scale: 0.9;
}

.btn-outline-primary {
    color: var(--theme-color);
    border-color: var(--theme-color);
}

.btn-outline-primary:hover {
    background: var(--theme-color) !important;
}