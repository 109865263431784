a.action-btn.btn-get-started.d-inline-flex.align-items-center.justify-content-center.align-self-center {
    margin-top: 10px;
    font-size: 12px !important;
    padding: 15px 18px;
}

a.action-btn.btn-get-started span {
    font-family: "Nunito", sans-serif;
    font-size: 15px;
}

.hero a.action-btn.btn-get-started i {
    margin-right: 10px;
}

.hero a.action-btn.btn-get-started:hover i {
    transform: translateX(0);
}

@media (max-width: 992px) {
    .hero h1.partnerSectionHeading {
        margin-top: 50px;
    }
}

.partnerSectionImage {
    scale: 0.8;
}