#hero.partner-bg {
    background: transparent;
    scroll-snap-align: center;
    height: 210%;
}

.row.spacer {
    margin-top: 40px;
}

.row.sec div img.img-fluid {
    width: 70%;
}

.row.sec .col-lg-6.hero-img.aos-init.aos-animate {
    display: flex;
    align-items: center;
    justify-content: center;
}

.row.sec .col-lg-6.d-flex.flex-column {
    scale: 0.825;
}