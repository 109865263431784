/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.logo {
    line-height: 1;
}

@media (min-width: 1200px) {
    .logo {
        width: 210px;
    }
}

.logo img {
    max-height: 26px;
    margin-right: 6px;
}

.dashboardUserInterface .logo span {
    font-size: 25px;
    font-weight: 700;
    color: #012970;
    font-family: "Nunito", sans-serif;
}

.dashboardUserInterface .header {
    transition: all 0.5s;
    z-index: 997;
    height: 60px;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
    padding-left: 20px;
    /* Toggle Sidebar Button */
    /* Search Bar */
}

.dashboardUserInterface .header .toggle-sidebar-btn {
    font-size: 32px;
    padding-left: 10px;
    cursor: pointer;
    color: #012970;
}

.dashboardUserInterface .header .search-bar {
    min-width: 360px;
    padding: 0 20px;
}

@media (max-width: 1199px) {
    .dashboardUserInterface .header .search-bar {
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
        background: white;
        z-index: 9999;
        transition: 0.3s;
        visibility: hidden;
        opacity: 0;
    }

    .dashboardUserInterface .header .search-bar-show {
        top: 60px;
        visibility: visible;
        opacity: 1;
    }
}

.dashboardUserInterface .header .search-form {
    width: 100%;
}

.dashboardUserInterface .header .search-form input {
    border: 0;
    font-size: 14px;
    color: #012970;
    border: 1px solid rgba(1, 41, 112, 0.2);
    padding: 7px 38px 7px 8px;
    border-radius: 3px;
    transition: 0.3s;
    width: 100%;
}

.dashboardUserInterface .header .search-form input:focus,
.dashboardUserInterface .header .search-form input:hover {
    outline: none;
    box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
    border: 1px solid rgba(1, 41, 112, 0.3);
}

.dashboardUserInterface .header .search-form button {
    border: 0;
    padding: 0;
    margin-left: -30px;
    background: none;
}

.dashboardUserInterface .header .search-form button i {
    color: #012970;
}

/*--------------------------------------------------------------
  # Header Nav
  --------------------------------------------------------------*/
.dashboardUserInterface .header-nav ul {
    list-style: none;
}

.dashboardUserInterface .header-nav > ul {
    margin: 0;
    padding: 0;
}

.dashboardUserInterface .header-nav .nav-icon {
    font-size: 22px;
    color: #012970;
    margin-right: 25px;
    position: relative;
}

.dashboardUserInterface .header-nav .nav-profile {
    color: #012970;
}

.dashboardUserInterface .header-nav .nav-profile img {
    max-height: 36px;
}

.dashboardUserInterface .header-nav .nav-profile span {
    font-size: 14px;
    font-weight: 600;
}

.dashboardUserInterface .header-nav .badge-number {
    position: absolute;
    inset: -2px -5px auto auto;
    font-weight: normal;
    font-size: 12px;
    padding: 3px 6px;
}

.dashboardUserInterface .header-nav .notifications {
    inset: 8px -15px auto auto !important;
}

.dashboardUserInterface .header-nav .notifications .notification-item {
    display: flex;
    align-items: center;
    padding: 15px 10px;
    transition: 0.3s;
}

.dashboardUserInterface .header-nav .notifications .notification-item i {
    margin: 0 20px 0 10px;
    font-size: 24px;
}

.dashboardUserInterface .header-nav .notifications .notification-item h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.dashboardUserInterface .header-nav .notifications .notification-item p {
    font-size: 13px;
    margin-bottom: 3px;
    color: #919191;
}

.dashboardUserInterface .header-nav .notifications .notification-item:hover {
    background-color: #f6f9ff;
}

.dashboardUserInterface .header-nav .messages {
    inset: 8px -15px auto auto !important;
}

.dashboardUserInterface .header-nav .messages .message-item {
    padding: 15px 10px;
    transition: 0.3s;
}

.dashboardUserInterface .header-nav .messages .message-item a {
    display: flex;
}

.dashboardUserInterface .header-nav .messages .message-item img {
    margin: 0 20px 0 10px;
    max-height: 40px;
}

.dashboardUserInterface .header-nav .messages .message-item h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #444444;
}

.dashboardUserInterface .header-nav .messages .message-item p {
    font-size: 13px;
    margin-bottom: 3px;
    color: #919191;
}

.dashboardUserInterface .header-nav .messages .message-item:hover {
    background-color: #f6f9ff;
}

.dashboardUserInterface .header-nav .profile {
    min-width: 240px;
    padding-bottom: 0;
    top: 8px !important;
}

.dashboardUserInterface .header-nav .profile .dropdown-header h6 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    color: #444444;
}

.dashboardUserInterface .header-nav .profile .dropdown-header span {
    font-size: 14px;
}

.dashboardUserInterface .header-nav .profile .dropdown-item {
    font-size: 14px;
    padding: 10px 15px;
    transition: 0.3s;
}

.dashboardUserInterface .header-nav .profile .dropdown-item i {
    margin-right: 10px;
    font-size: 18px;
    line-height: 0;
}

.dashboardUserInterface .header-nav .profile .dropdown-item:hover {
    background-color: #f6f9ff;
}

a.getstarted.logout-btn.scrollto {
    margin-right: 20px;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 996;
    transition: all 0.3s;
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #aab7cf transparent;
    box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
}

@media (max-width: 1199px) {
    .sidebar {
        left: -300px;
    }
}

.sidebar::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #aab7cf;
}

@media (min-width: 1200px) {
    .dashboardUserInterface #main,
    .dashboardUserInterface #footer {
        margin-left: 300px;
    }
}

@media (max-width: 1199px) {
    .toggle-sidebar .sidebar {
        left: 0;
    }
}

@media (min-width: 1200px) {
    .toggle-sidebar #main,
    .toggle-sidebar #footer {
        margin-left: 0;
    }

    .toggle-sidebar .sidebar {
        left: -300px;
    }
}

.sidebar-nav {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav li {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav .nav-item {
    margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
    font-size: 11px;
    text-transform: uppercase;
    color: #899bbd;
    font-weight: 600;
    margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: #012970;
    transition: 0.3;
    padding: 10px 15px;
    border-radius: 4px;
}

.sidebar-nav .nav-link i {
    font-size: 16px;
    margin-right: 10px;
    color: #012970;
}

.sidebar-nav .nav-link.collapsed, .sidebar-nav .nav-link.active {
    color: var(--theme-color);
    background: #fff;
}

.sidebar-nav .nav-link.collapsed i, .sidebar-nav .nav-link.active i {
    color: var(--theme-color);
}

.sidebar-nav .nav-link:hover {
    color: var(--theme-color);
    background: #f6f9ff;
}

.sidebar-nav .nav-link:hover i {
    color: var(--theme-color);
}

.sidebar-nav .nav-link .bi-chevron-down {
    margin-right: 0;
    transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
    transform: rotate(180deg);
}

.sidebar-nav .nav-content {
    padding: 5px 0 0 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav .nav-content a {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #012970;
    transition: 0.3;
    padding: 10px 0 10px 40px;
    transition: 0.3s;
}

.sidebar-nav .nav-content a i {
    font-size: 6px;
    margin-right: 8px;
    line-height: 0;
    border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
    color: var(--theme-color);
}

.sidebar-nav .nav-content a.active i {
    background-color: var(--theme-color);
}

.dashboardUserInterface #main {
    margin-top: 60px;
    padding: 20px 30px;
    transition: all 0.3s;
}

.pagetitle {
    margin-bottom: 10px;
}

.pagetitle h1 {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 600;
    color: #012970;
}

.dashboardUserInterface .card {
    margin-bottom: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.backgroundColor {
    background: #f6f9ff;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.dashboardLoaderWidget {
    display: flex;
    position: absolute;
    top: 110px;
    bottom: 0;
    left: 230px;
    right: 5px;
    align-items: center;
    justify-content: center;
    transition: 300ms;
}

@media (max-width: 1200px) {
    .dashboardUserInterface #main {
        padding: 20px;
    }
    .dashboardLoaderWidget {
        right: 5px;
        left: 5px;
    }
}

.toggle-sidebar div.dashboardLoaderWidget  {
    right: 5px;
    left: 5px;
}