.col-lg-4.col-md-6.d-flex.flex-column.align-items-center.justify-content-center.uipr {
    display: block !important;
    background: white;
    width: 80%;
    padding: 10px 0;
    max-width: 800px;
    border: 1px solid #8c8c8c;
    border-radius: 7px;
    font-size: 14px;
    text-indent: 10px;
    margin-bottom: 20px;
}

span.pr-type {
    display: inline-block;
    background: var(--theme-color);
    color: white;
    padding: 0 7px;
    border-radius: 7px;
    margin: 0 10px;
    opacity: 0.9;
    text-indent: 0;
}